import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface DynamicBoxProps {
  title: string;
  subtitle: string;
  text: string;
  children: ReactNode;
}

const DynamicBox: React.FC<DynamicBoxProps> = ({
  title,
  subtitle,
  text,
  children,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <h1 className="text-3xl md:text-xl lg:text-3xl mb-3 text-[#184070] font-medium">
        {t(title)}
      </h1>
      {subtitle === "" ? null : (
        <p className="mb-4 text-[#4B7BB7] text-xl md:text-xl lg:text-xl">
          {t(subtitle)}
        </p>
      )}
      {text === "" ? null : (
        <p className="mb-8 text-[#000000] md:text-md lg:text-lg">{t(text)}</p>
      )}

      {children}
    </div>
  );
};

export default DynamicBox;
